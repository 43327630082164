<template>
  <div v-if="!loading">
    <v-card flat tile class="pb-0 mb-0">
      <ImageManager
        v-if="term.termid"
        :id="term.termid"
        :connection-type-id="connectionType.terms"
        class="pb-2"
      />
      <v-card-text class="text-caption pb-0 mb-0">
        <router-link :to="{ name: 'terms_landing', query: { tab: termTypeNameById(term.termtypeid).name } }">{{ termTypeNameById(term.termtypeid).name }}s</router-link>
      </v-card-text>
      <v-card-title class="pt-0 pb-0 mb-0">
        <router-link :to="{ name: 'term_edit', params: { termId: term.termid } }">
          <strong>{{ term.englishtitle }}</strong> 
        </router-link>
      </v-card-title>

      <v-card-subtitle class="text-caption pb-0 mb-0 pt-2">Translations</v-card-subtitle>
      <div class="d-flex flex-row">
        <v-card 
          v-for="termTranslation in termTranslations"
          :key="termTranslation.termtranslationid"
          tile
          flat
          class="pa-0 ma-0" 
        >
          <v-card-text class="mb-0 mt-0 pb-0 pt-1">
            <strong>{{ termTranslation.name }}</strong>
          </v-card-text>
          <v-card-subtitle class="caption mt-0 pt-0">{{ languageNameById(termTranslation.languageid) }}</v-card-subtitle>
        </v-card>
      </div>
    </v-card>

    <v-card class="pt-2 pb-2" tile flat>
      <v-card-text class="text-h6">Related Items</v-card-text>
      <TermConnectedListing :term-id="term.termid" :term-type-id="term.termtypeid"/>
    </v-card>

    <debug v-if="debug" class="pt-4">
      <template slot="sub-heading">TermsPage</template>
      <template slot="info">
        term={{ term }}<br/>
        similarTerms={{ similarTerms }}<br/>
        termTranslations={{ termTranslations }}
      </template>
    </debug>

  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DocAdmin from '@/store/data'
import Vue from 'vue'
import Debug from '@/components/common/Debug'
import configs from '@/configs'
import TermConnectedListing from '@/components/terms/TermConnectedListing'
import ImageManager from '@/components/ImageManager'

export default {
  name: 'TermsPage',
  components: {
    Debug,
    TermConnectedListing,
    ImageManager
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    term: undefined,
    termTranslations: [],
    similarTerms: undefined,
    loading: true,
    tabs: null
  }),
  computed: {
    ...mapGetters('languages', ['languageNameById']),
    ...mapGetters('terms', ['termTypeNameById']),
    ...mapGetters('app', ['mainDrawer']),
    ...mapState('languages', ['languages']),
    ...mapState('app', ['debug']),
    ...mapState('terms', ['termTypes'])
  },
  async created() {
    if (!this.languages) {
      await this.$store.dispatch('languages/getLanguages')
    }
    if (!this.termTypes) {
      await this.$store.dispatch('terms/getTermTypes')
    } 
    this.getTerm()
  },
  methods: {
    async getTerm() {
      const term = await DocAdmin.terms.get(this.$route.params.termid)
      this.term = term.data
      
      if (!this.term.termid) {
        console.error(`TermsPage.getTerm no term found.`)
        return
      }

      // get term Translations
      const termTranslations = await DocAdmin.termTranslations.list({ params: { termid: this.term.termid }})
      this.termTranslations = termTranslations.data.results

      // get similar terms
      const terms = await DocAdmin.terms.list({params: { limit: 10, search: this.term.englishtitle.slice(1, -1) }})
      this.similarTerms = terms.data.results
    
      this.loading = false
    }
  }
}
</script>
