<template>
  <v-card flat>
    <ImageManager
      v-if="term"
      :id="term.termid"
      :connection-type-id="connectionType.terms"
      :allow-upload="true"
      class="pb-2"
    />
    <v-card-title>Editing Term {{ term.englishtitle }}</v-card-title>
    <v-card-subtitle>
     <small><a @click="$router.go(-1)" style="cursor: pointer; color: blue; text-decoration: underline;">go back</a></small> 
    </v-card-subtitle>
    <v-card-text>
      <v-form @submit.prevent="updateTerm">
        <v-row align="center" dense no-gutters>
          <v-col cols="12" md="8">
            <v-text-field 
              v-model="term.englishtitle" 
              label="English Title" 
              outlined 
              dense 
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select 
              v-model="term.termtypeid" 
              :items="termTypes" 
              item-text="name" 
              item-value="termtypeid" 
              label="Type" 
              outlined 
              dense 
            />
          </v-col>
        </v-row>
        <v-btn type="submit" color="primary" class="mt-3">Save</v-btn>
      </v-form>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="title-column">Title</th>
              <th>Language</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(translation, index) in translations" :key="translation.termtranslationid || index">
              <td>
                <v-text-field
                  v-model="translation.name"
                  placeholder="Enter title"
                  :disabled="!translation.isEditing"
                />
              </td>
              <td>
                <v-select
                  v-model="translation.languageid"
                  :items="getLanguageOptions(translation.languageid)"
                  item-text="name"
                  item-value="languageid"
                  dense
                  hide-details
                  placeholder="Select language"
                  class="language-select"
                  :disabled="!translation.isEditing"
                />
              </td>
              <td>
                <v-btn-toggle tile borderless dense>
                  <v-btn @click="addEmptyTranslation"><v-icon dense color="primary">mdi-plus-circle-outline</v-icon></v-btn>
                  <v-btn @click="toggleEdit(translation)"><v-icon dense color="orange">{{ translation.isEditing ? 'mdi-check' : 'mdi-pencil' }}</v-icon></v-btn>
                  <v-btn v-if="translation.isEditing" @click="saveTranslation(translation)"><v-icon dense color="green">mdi-content-save</v-icon></v-btn>
                  <v-btn @click="removeTranslation(translation.termtranslationid)"><v-icon dense color="red">mdi-delete</v-icon></v-btn>
                </v-btn-toggle>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import docAdminApi from "@/store/data/docadmin.api";
import { mapState, mapGetters } from "vuex";
import ImageManager from '@/components/ImageManager'
import configs from '@/configs'

export default {
  props: {
    termId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    ImageManager,
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    term: {},
    translations: [],
    termTypes: [],
  }),
  computed: {
    ...mapState('app', ['debug']),
    ...mapState('languages', ['languages']),
    ...mapGetters('languages', ['languageShortCodeById', 'languageNameById']),
    getLanguageOptions() {
      return (currentLanguageId) => {
        if (!Array.isArray(this.languages) || this.languages.length === 0) {
          return [];
        }

        const usedLanguageIds = this.translations
          .filter(t => t.languageid !== currentLanguageId)
          .map(t => t.languageid);

        return this.languages.filter(language => !usedLanguageIds.includes(language.languageid));
      };
    },
  },
  methods: {
    async fetchTerm() {
      const response = await docAdminApi.terms.get(this.termId);
      this.term = response.data;
    },
    async fetchTranslations() {
      const response = await docAdminApi.terms.translations.list({
        params: { termid: this.termId },
      });
      this.translations = response.data.results;
      // Use Vue.set to ensure reactivity for isEditing
      this.translations.forEach((translation, index) => {
        Vue.set(translation, "isEditing", false);
      });
    },
    async fetchTermTypes() {
      const response = await docAdminApi.termTypes.list();
      this.termTypes = response.data.results;
    },
    async updateTerm() {
      await docAdminApi.terms.update(this.termId, this.term);
      this.$store.dispatch("app/setSnackBar", "Term updated successfully!")
      this.$router.go(-1); // Navigate back to the previous page
    },
    toggleEdit(translation) {
      translation.isEditing = !translation.isEditing;
    },
    async saveTranslation(translation) {
      if (translation.termtranslationid) {
        await docAdminApi.terms.translations.update(translation.termtranslationid, translation);
      } else {
        const payload = { ...translation, termid: this.termId };
        await docAdminApi.terms.translations.create(payload);
      }
      translation.isEditing = false; // Exit edit mode after saving
      this.fetchTranslations();
    },
    async removeTranslation(translationId) {
      if (!translationId) return; // Prevent errors for new rows
      try {
        await docAdminApi.terms.translations.delete(translationId); // Pass the translation ID directly
        this.fetchTranslations();
      } catch (error) {
        console.error("Failed to delete translation:", error);
        this.$store.dispatch("app/setSnackBar", "An error occurred while deleting the translation.")
      }
    },
    addEmptyTranslation() {
      // Use Vue.set to ensure reactivity for new translations
      const newTranslation = { name: "", languageid: null, isEditing: true };
      this.translations.push(newTranslation);
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchTerm(),
      this.fetchTranslations(),
      this.fetchTermTypes()
    ]);
  },
};
</script>

<style scoped>
.language-select {
  max-width: 120px;
}
.title-column {
  width: 50%; /* Adjust the percentage as needed */
}
</style>